import React from 'react'
import Helmet from 'react-helmet'
import { NotFound, Layout, BlogSlider, TextBox, CallUs, BannerLinks } from '../components'

import cards from '../components/BlogSlider/DefaultCards'
import boxes from '../components/BannerLinks/DefaultBox'
import contacts from '../components/CallUs/DefaultContacts'

const NotFoundPage = () => (
  <Layout>
    <Helmet
      title="Not Found 404"
      meta={[
        {
          name: 'description',
          content:
            'Diese Seite gibt es nicht auf holzweg.com. Vielleicht interessieren sich aber für folgende Inhalte:'
        },
        {
          name: 'keywords',
          content:
            '404 holzweg, holzweg Webagentur, 404'
        },
        {
          property: 'og:description',
          content:
            'Diese Seite gibt es nicht auf holzweg.com. Vielleicht interessieren sich aber für folgende Inhalte:'
        },
        {
          property: 'og:image',
          content: 'https://kooperation.holzweg.com/wp-content/uploads/2019/11/holzweglogo.png'
        }
      ]}
    />
    <NotFound />
    <TextBox title="Das könnte Sie auch interessieren:" titleMods={['center']}/>
    <BlogSlider cards={cards} singleRow={true} />
    <CallUs contacts={contacts} title="Rufen Sie uns an!" />
    <TextBox
      title="Walk with us on the holzweg"
      text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
    />
    <BannerLinks boxes={boxes} />
  </Layout>
)

export default NotFoundPage
